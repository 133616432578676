<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Proyecciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Proyecciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <a class="ir-arriba" @click="irArriba()">
          <span class="fa-stack">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fa fa-arrow-up fa-stack-1x fa-inverse"></i>
          </span>
        </a>
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <fieldset class="well card shadow">
                  <legend
                    class="well-legend text-bold bg-frontera text-light rounded-bottom shadow"
                  >
                    {{ accion }} Proyección
                  </legend>
                  <div class="row justify-content-center">
                    <!-- Meses -->
                    <div class="form-group col-md-2">
                      <label for="mes">Mes</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="form.mes"
                        :class="
                          $v.form.mes.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="accion == 'Editar'"
                        @change="getNombre"
                      >
                        <option value="">Seleccionar...</option>
                        <option
                          v-for="mes in listasForms.meses"
                          :key="mes.numeracion"
                          :value="mes.numeracion"
                        >
                          {{ mes.descripcion }}
                        </option>
                      </select>
                    </div>
                    <!-- Tipo Proyeccion -->
                    <div class="form-group col-md-2">
                      <label for="tipo_proyeccion">Tipo Proyeccion</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="form.tipo_proyeccion"
                        :class="
                          $v.form.tipo_proyeccion.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @change="getNombre"
                        :disabled="accion == 'Editar'"
                      >
                        <option value="">Seleccionar...</option>
                        <option value="1">Planilla 1Q</option>
                        <option value="2">Planilla 2Q</option>
                        <option value="3">Mensual</option>
                      </select>
                    </div>
                    <!-- Año -->
                    <div class="form-group col-md-2">
                      <label for="ano">Año</label>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        v-model="form.ano"
                        :class="
                          $v.form.ano.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="accion == 'Editar'"
                        @input="getNombre()"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <!-- Nombre -->
                    <div class="form-group col-md-6">
                      <label for="bloque">Nombre</label>
                      <textarea
                        class="form-control form-control-sm"
                        v-model="form.nombre"
                        :class="
                          $v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        disabled
                      ></textarea>
                    </div>
                    <!-- Observaciones -->
                    <div class="form-group col-md-6">
                      <label for="clase_vehiculo">Observaciones</label>
                      <textarea
                        class="form-control form-control-sm"
                        v-model="form.observacion"
                        :disabled="accion == 'Editar'"
                      ></textarea>
                    </div>
                  </div>
                  <!-- Botones -->
                  <div class="text-center">
                    <button
                      type="button"
                      class="btn bg-gradient-secondary shadow"
                      @click="back()"
                    >
                      <i class="fas fa-reply"></i> Volver
                    </button>
                    <button
                      type="button"
                      class="btn bg-gradient-primary ml-1 shadow"
                      @click="save()"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.proyeccionHidrocarburos.create' ||
                            'hidrocarburos.proyeccionHidrocarburos.edit'
                        ) &&
                          !$v.form.$invalid &&
                          bloques &&
                          bloques.length
                      "
                      :disabled="bloq"
                    >
                      <i class="fas fa-paper-plane"></i> Guardar
                    </button>
                  </div>
                </fieldset>
              </div>
            </div>

            <!-- Asignación Bloques -->
            <div class="row">
              <div class="col-md-12">
                <fieldset class="well card shadow">
                  <legend
                    class="well-legend text-bold bg-frontera text-light rounded-bottom shadow"
                  >
                    Asignación Bloques
                  </legend>
                  <div class="row justify-content-center">
                    <!-- Bloque -->
                    <div class="form-group col-md-10">
                      <label for="bloque">Bloque</label>
                      <v-select
                        placeholder="Bloque"
                        label="nombre"
                        v-model="bloques"
                        :options="listasForms.bloques"
                        @input="selectBloque()"
                        multiple
                        :class="[
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                          bloques && bloques.length
                            ? 'rounded border border-success'
                            : 'rounded border border-danger',
                        ]"
                        :disabled="bloq"
                      >
                      </v-select>
                    </div>
                    <!-- Estado -->
                    <div class="form-group col-md-2">
                      <label for="estado">Estado</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="form.estado"
                        :class="
                          $v.form.estado.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        @change="cambiarEstado()"
                        :disabled="bloq"
                      >
                        <option value="">Seleccionar...</option>
                        <option
                          v-for="estado in listasForms.estados"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                          :disabled="
                            estado.numeracion === 2 && accion == 'Crear'
                          "
                        >
                          {{ estado.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <!-- Detalle Proyección -->
            <div v-show="form.id">
              <TifProyeccionTransporteInterno
                ref="tifProyeccionTransporteInterno"
              />
            </div>
            <!-- Planta Externa -->
            <div v-show="form.id">
              <TifProyeccionPlantaExterna ref="tifProyeccionPlantaExterna" />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import $ from "jquery";
import Loading from "../../../../components/Loading";
import { required, minValue } from "vuelidate/lib/validators";
import TifProyeccionTransporteInterno from "./TifProyeccionTransporteInterno";
import TifProyeccionPlantaExterna from "./TifProyeccionPlantaExterna";

$(document).ready(function() {
  $(window).scroll(function() {
    if ($(this).scrollTop() > 0) {
      $(".ir-arriba").slideDown(600);
    } else {
      $(".ir-arriba").slideUp(600);
    }
  });
});

export default {
  name: "TifProyeccionForm",
  components: {
    vSelect,
    Loading,
    TifProyeccionTransporteInterno,
    TifProyeccionPlantaExterna,
  },
  data() {
    return {
      accion: null,
      cargando: false,
      bloq: false,
      listasForms: {
        bloques: [],
        estados: [],
        meses: [],
      },
      bloques: [],
      form: {
        bloque_id: [],
        nombre: null,
        mes: null,
        ano: null,
        tipo_proyeccion: null,
        observacion: null,
        estado: null,
      },
    };
  },

  validations: {
    form: {
      nombre: {
        required,
      },
      mes: {
        required,
      },
      ano: {
        required,
        minValue: minValue(2000),
      },
      tipo_proyeccion: {
        required,
      },
      estado: {
        required,
      },
    },
  },

  methods: {
    async init() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      await this.listas();
      if (this.accion == "Editar") {
        this.metodo = "PUT";
        this.form = this.$route.params.data_edit;
        this.bloques = this.form.bloques;
        this.bloq = this.$route.params.data_edit.estado == 2 ? true : false;
        await this.selectBloque();
        await this.$refs.tifProyeccionTransporteInterno.dataDetalle();
        await this.$refs.tifProyeccionPlantaExterna.dataDetallePlanta();
        this.cargando = false;
      } else {
        this.metodo = "POST";
        this.form.estado = 1;
        this.bloq = false;
        this.cargando = false;
      }
      this.cargando = false;
    },

    async save() {
      let me = this;
      if (!this.$v.form.$invalid) {
        this.form.bloques = this.bloques;
        me.form.cambiarEstado = false;
        me.cargando = true;
        await axios({
          method: this.metodo,
          url: "/api/tif/proyecciones",
          data: this.form,
        }).then(async (response) => {
          me.$route.params.accion = "Editar";
          me.$route.params.data_edit = await response.data.proyeccion;
          await this.$refs.tifProyeccionTransporteInterno.getLiquidaciones(
            response.data.liquidaciones,
            1
          );
          await this.$refs.tifProyeccionPlantaExterna.getViajes(
            response.data.viajesPlanta,
            1
          );
          await this.$swal({
            icon: "success",
            title: "Se guardó la proyección exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          me.cargando = false;
        });
        me.cargando = false;
      }
    },

    async cambiarEstado() {
      let me = this;
      me.form.cambiarEstado = true;
      if (!me.$v.form.$invalid) {
        me.$swal({
          title: "Está seguro de cambiar el estado?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Cambiar!",
        }).then(async (result) => {
          if (result.value) {
            me.form.bloques = me.bloques;
            me.cargando = true;
            await axios({
              method: me.metodo,
              url: "/api/tif/proyecciones",
              data: me.form,
            }).then(async (response) => {
              me.$route.params.accion = "Editar";
              me.$route.params.data_edit = await response.data.proyeccion;
              await this.init();
              await me.$swal({
                icon: "success",
                title: "Se guardó la proyección exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              me.cargando = false;
            });
            me.cargando = false;
          }
        });
      }
    },

    async getEstados() {
      let me = this;
      await axios.get("/api/lista/28").then((response) => {
        me.listasForms.estados = response.data;
      });
    },

    async getMes() {
      let me = this;
      await axios.get("/api/lista/152").then((response) => {
        me.listasForms.meses = response.data;
      });
    },

    async getBloques() {
      let me = this;
      await axios.get("/api/admin/bloques/lista").then((response) => {
        me.listasForms.bloques = response.data;
      });
    },

    async listas() {
      await this.getEstados();
      await this.getMes();
      await this.getBloques();
    },

    async selectBloque() {
      this.form.bloque_id = null;
      if (this.bloques) {
        this.form.bloque_id = this.bloques.map((b) => b.id);
        await this.getNombre();
      }
    },

    async getNombre() {
      if (this.form.ano && this.form.mes && this.form.bloque_id) {
        /* let [year, monthNumber] = this.form.fecha_inicial.split("-");*/

        let mesD = this.listasForms.meses.filter(
          (m) => m.numeracion === this.form.mes
        );

        let bloques = this.bloques.map((bloque) => bloque.nombre);
        this.form.nombre =
          "Proyeccion de " +
          bloques +
          "-" +
          mesD[0].descripcion +
          "-" +
          this.form.ano;
      }
    },

    irArriba() {
      $("body,html").animate({ scrollTop: "0px" }, 1000);
    },

    back() {
      if (this.form.id && this.form.estado == 1) {
        this.$swal({
          title: "Esta seguro de volver?",
          text:
            "Se perderá todos los detalles de la proyección " +
            this.form.nombre +
            " que no se hayan guardado!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Volver!",
        }).then(async (result) => {
          if (result.value) {
            return this.$router.replace("/Tif/TifProyecciones");
          }
        });
      } else {
        return this.$router.replace("/Tif/TifProyecciones");
      }
    },
  },

  async mounted() {
    await this.init();
  },
};
</script>

<style>
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  min-height: 20px;
  padding: 14px;
  margin-bottom: 10px;
  border: 2px solid #001871;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.ir-arriba {
  display: none;
  background-repeat: no-repeat;
  font-size: 20px;
  color: black;
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 8px;
  z-index: 2;
}
</style>

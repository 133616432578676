<template>
  <div class="card">
    <div class="card-header pt-1 pb-1 bg-frontera text-white">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-10">
            <h5 class="mb-0 p-2">
              Detalle Proyección Planta Externa
            </h5>
          </div>
          <div class="col-md-2 pt-3 pb-3">
            <div class="btn-group float-right">
              <button
                type="button"
                data-card-widget="collapse"
                data-toggle="tooltip"
                title="Collapse"
                class="btn btn-tool"
              >
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-10"></div>
        <div class="col-md-2">
          <div class="btn-group float-right">
            <button
              class="btn bg-info shadow"
              @click="saveDetalle()"
              v-if="
                $store.getters.can(
                  'tif.proyecciones.saveDetallePlantaExterna'
                ) &&
                  this.viajes &&
                  this.viajes.length > 0
              "
              :disabled="
                $parent.bloq ||
                  [1, 2].includes(
                    $parent.$refs.tifProyeccionTransporteInterno
                      .visualizar_proyeccion
                  )
              "
            >
              <i class="fas fa-sync-alt fa-spin"></i> Guardar Detalles
            </button>
            <button
              type="button"
              class="btn bg-primary ml-1 text-white shadow"
              id="agregarViaje"
              @click="addViaje()"
              :disabled="
                [1, 2].includes(visualizar_viaje) ||
                  $parent.bloq ||
                  [1, 2].includes(
                    $parent.$refs.tifProyeccionTransporteInterno
                      .visualizar_proyeccion
                  )
              "
              style="cursor: pointer"
            >
              <i class="fas fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="table-responsive mt-2">
          <table
            class="table table-bordered table-striped table-hover table-sm text-nowrap"
          >
            <thead class="bg-dark">
              <tr class="text-center">
                <th rowspan="2" v-if="!$parent.bloq">Acción</th>
                <th rowspan="2">#</th>
                <th rowspan="2">
                  Producto
                </th>
                <th rowspan="2">
                  Tipo Vehículo
                </th>
                <th rowspan="2">
                  Empresa
                </th>
                <th rowspan="2">
                  Cant Turnos
                </th>
                <th rowspan="2">
                  Cant Vehículos
                </th>
                <th rowspan="2">Servicio T.O.V <small>(BLS)</small></th>
                <th rowspan="2">
                  Galones
                </th>
                <th colspan="3">Total</th>
              </tr>
              <tr class="text-center">
                <th>Tarifa</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(via, index) in viajes" :key="index">
                <!-- Campos Botones -->
                <td class="text-center" v-if="!$parent.bloq">
                  <div class="btn-group" v-if="visualizar_viaje != 2">
                    <button
                      type="button"
                      class="btn bg-navy"
                      :disabled="
                        visualizar_viaje != -1 ||
                          [1, 2].includes(
                            $parent.$refs.tifProyeccionTransporteInterno
                              .visualizar_proyeccion
                          )
                      "
                      @click="editViaje(index)"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <button
                      class="btn bg-danger"
                      :disabled="
                        visualizar_viaje != -1 ||
                          [1, 2].includes(
                            $parent.$refs.tifProyeccionTransporteInterno
                              .visualizar_proyeccion
                          )
                      "
                      @click="destroyViaje(index)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                  <div
                    class="btn-group"
                    v-if="
                      visualizar_viaje == 2 && indice_actualizar_viaje == index
                    "
                  >
                    <button
                      type="button"
                      class="btn bg-success"
                      v-show="!$v.viaje.$invalid"
                      @click="saveUpdateViaje(index)"
                    >
                      <i class="fas fa-save"></i>
                    </button>
                    <button class="btn bg-danger" @click="cancelViaje()">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                  <div
                    class="btn-group"
                    v-if="
                      visualizar_viaje == 2 && indice_actualizar_viaje !== index
                    "
                  >
                    <button
                      type="button"
                      class="btn bg-navy"
                      disabled
                      @click="editViaje(index)"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <button
                      class="btn bg-danger"
                      disabled
                      @click="destroyViaje(index)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </td>
                <!-- Campo Posiciones -->
                <td class="text-center">
                  <span class="badge bg-navy">
                    {{ index + 1 }}
                  </span>
                </td>
                <!-- Campo Producto -->
                <td>
                  <div
                    v-if="
                      indice_actualizar_viaje === index &&
                        visualizar_viaje === 2
                    "
                  >
                    <v-select
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.productos"
                      v-model="producto"
                      :class="[
                        $v.viaje.producto_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                        'form-control form-control-sm p-0',
                      ]"
                      @input="selectProducto()"
                    ></v-select>
                  </div>
                  <div v-else class="text-center">
                    {{ via.producto.nombre }}
                  </div>
                </td>
                <!-- Campo Tipo Vehículo -->
                <td>
                  <div
                    v-if="
                      indice_actualizar_viaje === index &&
                        visualizar_viaje === 2
                    "
                  >
                    <v-select
                      placeholder="Tipo Vehículo"
                      label="nombre"
                      :options="listasForms.tipo_vehiculos"
                      v-model="tipo_vehiculo"
                      :class="[
                        $v.viaje.tipo_vehiculo_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                        'form-control form-control-sm p-0',
                      ]"
                      @input="selectProducto()"
                    ></v-select>
                  </div>
                  <div v-else class="text-center">
                    {{ via.tipo_vehiculo.nombre }}
                  </div>
                </td>
                <!-- Campo Empresa -->
                <td>
                  <div
                    v-if="
                      indice_actualizar_viaje === index &&
                        visualizar_viaje === 2
                    "
                  >
                    <v-select
                      placeholder="Nombre"
                      label="razon_social"
                      :options="listasForms.empresas"
                      v-model="empresa"
                      :class="[
                        $v.viaje.empresa_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                        'form-control form-control-sm p-0',
                      ]"
                      @input="selectEmpresa()"
                    ></v-select>
                  </div>
                  <div v-else class="text-center">
                    {{ via.empresa.razon_social }}
                  </div>
                </td>
                <!-- Campo Cantidad Vehículo -->
                <td>
                  <div
                    v-if="
                      indice_actualizar_viaje === index &&
                        visualizar_viaje === 2
                    "
                  >
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      placeholder="Cant Turno"
                      v-model="viaje.cant_turno"
                      :class="
                        $v.viaje.cant_turno.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                  <div v-else class="text-center">
                    {{ via.cant_turno }}
                  </div>
                </td>
                <!-- Campo Cantidad Vehículo -->
                <td>
                  <div
                    v-if="
                      indice_actualizar_viaje === index &&
                        visualizar_viaje === 2
                    "
                  >
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      placeholder="Cant Vh"
                      v-model="viaje.cant_vh"
                      :class="
                        $v.viaje.cant_vh.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                  <div v-else class="text-center">
                    {{ via.cant_vh }}
                  </div>
                </td>
                <!-- Campo Servicio T.O.V -->
                <td>
                  <div
                    v-if="
                      indice_actualizar_viaje === index &&
                        visualizar_viaje === 2
                    "
                  >
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      placeholder="Bls"
                      v-model="viaje.barriles_servicio"
                      :class="
                        $v.viaje.barriles_servicio.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="[calcGalonesViaje(), totales()]"
                    />
                  </div>
                  <div v-else class="text-center">
                    {{ via.barriles_servicio }}
                  </div>
                </td>
                <!-- Campo Galones -->
                <td class="text-center">
                  <div
                    v-if="
                      indice_actualizar_viaje === index &&
                        visualizar_viaje === 2
                    "
                  >
                    {{ viaje.galones }}
                  </div>
                  <div v-else class="text-center">
                    {{ via.galones }}
                  </div>
                </td>
                <!-- Campo Tarifa -->
                <td class="text-center">
                  <div
                    v-if="
                      indice_actualizar_viaje === index &&
                        visualizar_viaje === 2
                    "
                  >
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      placeholder="Tarifa"
                      v-model="viaje.valor_tarifa"
                      :class="
                        $v.viaje.valor_tarifa.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="totales()"
                    />
                  </div>
                  <div v-else class="text-center">
                    ${{ parseInt(via.valor_tarifa).toLocaleString() }}
                  </div>
                </td>
                <!-- Campo Valor -->
                <td class="text-center">
                  <div
                    v-if="
                      indice_actualizar_viaje === index &&
                        visualizar_viaje === 2
                    "
                  >
                    {{ viaje.total_valor_format }}
                  </div>
                  <div v-else class="text-center">
                    {{ via.total_valor_format }}
                  </div>
                </td>
              </tr>
              <!-- Crear Datos -->
              <tr v-if="visualizar_viaje === 1">
                <!-- Campo Botones -->
                <td>
                  <div class="btn-group float-right">
                    <button
                      class="btn bg-success"
                      v-show="!$v.viaje.$invalid"
                      @click="saveViaje()"
                    >
                      <i class="fas fa-save"></i>
                    </button>
                    <button class="btn bg-danger" @click="cancelViaje()">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </td>
                <!-- Campo Producto -->
                <td>
                  <v-select
                    placeholder="Producto"
                    label="nombre"
                    :options="listasForms.productos"
                    v-model="producto"
                    :class="[
                      $v.viaje.producto_id.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                      'form-control form-control-sm p-0',
                    ]"
                    @input="selectProducto()"
                  ></v-select>
                </td>
                <!-- Campo Tipo Vehículo -->
                <td>
                  <v-select
                    placeholder="Tipo Vehículo"
                    label="nombre"
                    :options="listasForms.tipo_vehiculos"
                    v-model="tipo_vehiculo"
                    :class="[
                      $v.viaje.tipo_vehiculo_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                      'form-control form-control-sm p-0',
                    ]"
                    @input="selectTipoVehiculo()"
                  ></v-select>
                </td>
                <!-- Campo Empresa -->
                <td>
                  <v-select
                    placeholder="Empresa"
                    label="razon_social"
                    :options="listasForms.empresas"
                    v-model="empresa"
                    :class="[
                      $v.viaje.empresa_id.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                      'form-control form-control-sm p-0',
                    ]"
                    @input="selectEmpresa()"
                  ></v-select>
                </td>
                <!-- Campo Cant Vh -->
                <td>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    placeholder="Cant Vh"
                    v-model="viaje.cant_vh"
                    :class="
                      $v.viaje.cant_vh.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </td>
                <!-- Campo Servicio T.O.V Barriles -->
                <td>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    placeholder="Bls"
                    v-model="viaje.barriles_servicio"
                    :class="
                      $v.viaje.barriles_servicio.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @input="[calcGalonesViaje(), totales()]"
                  />
                </td>
                <!-- Campo Galones -->
                <td class="text-center">
                  {{ viaje.galones }}
                </td>
                <!-- Campo Tarifa -->
                <td class="text-center">
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    placeholder="Tarifa"
                    v-model="viaje.valor_tarifa"
                    :class="
                      $v.viaje.valor_tarifa.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    @input="totales()"
                  />
                </td>
                <!-- Campo Total -->
                <td class="text-center">
                  {{ viaje.total_valor_format }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "TifProyeccionPlantaExterna",
  components: {
    vSelect,
  },

  data() {
    return {
      viajes: [],
      visualizar_viaje: -1,
      indice_actualizar_viaje: -1,
      empresa: {},
      producto: {},
      tipo_vehiculo: {},
      listasForms: {
        empresas: [],
        productos: [],
        tipo_vehiculos: [],
      },
      viaje: {
        id: null,
        producto: null,
        producto_id: null,
        tipo_vehiculo: null,
        tipo_vehiculo_id: null,
        empresa: null,
        empresa_id: null,
        cant_vh: null,
        barriles_servicio: null,
        galones: null,
        valor_tarifa: null,
        valor_format_tarifa: null,
        valor_total: null,
        total_valor_format: null,
      },
    };
  },

  validations: {
    viaje: {
      producto_id: {
        required,
      },
      tipo_vehiculo_id: {
        required,
      },
      empresa_id: {
        required,
      },
      cant_vh: {
        required,
      },
      cant_turno: {
        required,
      },
      barriles_servicio: {
        required,
      },
      galones: {
        required,
      },
      valor_tarifa: {
        required,
      },
      valor_total: {
        required,
      },
    },
  },

  methods: {
    async saveDetalle() {
      let me = this;
      me.$parent.cargando = true;
      await axios({
        method: "PUT",
        url: "/api/tif/proyecciones/saveDetallePlanta",
        data: {
          viajes: me.viajes,
          proyeccion_tif_planta_externa_id: me.$parent.form.id,
        },
      }).then(async (response) => {
        await me.getViajes(response.data.planta_externa_det_proyecciones, 2);
        await me.$swal({
          icon: "success",
          title: "Se guardó el detalle Tif exitosamente...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        me.$parent.cargando = false;
      });
      me.$parent.cargando = false;
    },

    async limpiarDatos() {
      this.viaje = {
        id: null,
        producto: null,
        producto_id: null,
        tipo_vehiculo: null,
        tipo_vehiculo_id: null,
        empresa: null,
        empresa_id: null,
        cant_vh: null,
        barriles_servicio: null,
        galones: null,
        valor_tarifa: null,
        valor_format_tarifa: null,
        valor_total: null,
        total_valor_format: null,
      };
      this.producto = {};
      this.tipo_vehiculo = {};
      this.empresa = {};
    },

    async addViaje() {
      await this.limpiarDatos();
      this.viaje.proyeccion_tif_planta_externa_id = this.$parent.form.id;
      this.visualizar_viaje = 1;
    },

    async saveViaje() {
      this.viajes.push(this.viaje);
      this.visualizar_viaje = -1;
    },

    async cancelViaje() {
      await this.limpiarDatos();
      this.visualizar_viaje = -1;
    },

    async saveUpdateViaje(index) {
      this.viajes[index] = this.viaje;
      this.visualizar_viaje = -1;
    },

    editViaje(index) {
      this.indice_actualizar_viaje = index;
      this.visualizar_viaje = 2;
      this.producto = this.viajes[index].producto;
      this.tipo_vehiculo = this.viajes[index].tipo_vehiculo;
      this.empresa = this.viajes[index].empresa;
      this.viaje = { ...this.viajes[index] };
    },

    destroyViaje(index) {
      this.viajes.splice(index, 1);
    },

    calcGalonesViaje() {
      this.viaje.galones = this.viaje.barriles_servicio
        ? parseFloat(this.viaje.barriles_servicio) * 42
        : null;
    },

    async dataDetallePlanta() {
      await this.getTipoVehiculo();
      await this.getEmpresas();
      await this.getProductos();
      this.viajes =
        this.viajes && this.viajes.length > 0
          ? this.viajes
          : this.$parent.form.planta_externa_det_proyecciones;
      if (this.viajes && this.viajes.length > 0) {
        for (let i = 0; i < this.viajes.length; i++) {
          this.viajes[i].valor_format_tarifa = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            this.viajes[i].valor_tarifa
          );

          this.viajes[i].total_valor_format = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            this.viajes[i].valor_total
          );
        }
      }
    },

    async getProductos() {
      let me = this;
      await axios
        .get("api/admin/productos/lista", {
          params: {
            linea_negocio_id: [2],
          },
        })
        .then((response) => {
          me.listasForms.productos = response.data;
        });
    },

    async getTipoVehiculo() {
      let me = this;
      await axios
        .get("api/admin/tiposVehiculos/lista", {
          params: {
            linea_negocio_id: [2],
          },
        })
        .then(function(response) {
          me.listasForms.tipo_vehiculos = response.data;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getEmpresas() {
      await axios
        .get("/api/admin/empresas/lista", {
          params: {
            linea_negocio_id: [2],
          },
        })
        .then((response) => {
          this.listasForms.empresas = response.data;
        });
    },

    async selectProducto() {
      this.viaje.producto_id = null;
      if (this.producto) {
        this.viaje.producto_id = this.producto.id;
        this.viaje.producto = {
          id: this.producto.id,
          nombre: this.producto.nombre,
        };
        /* await this.buscarTarifaUni(); */
      }
    },

    async selectTipoVehiculo() {
      this.viaje.tipo_vehiculo_id = null;
      if (this.tipo_vehiculo) {
        this.viaje.tipo_vehiculo_id = this.tipo_vehiculo.id;
        this.viaje.tipo_vehiculo = {
          id: this.tipo_vehiculo.id,
          nombre: this.tipo_vehiculo.nombre,
        };
        /* await this.buscarTarifaUni(); */
      }
    },

    async selectEmpresa() {
      this.viaje.empresa_id = null;
      if (this.empresa) {
        this.viaje.empresa_id = this.empresa.id;
        this.viaje.empresa = {
          id: this.empresa.id,
          razon_social: this.empresa.razon_social,
        };
        /* await this.buscarTarifaUni(); */
      }
    },

    async getViajes(viajes, accion) {
      this.viajes = [];
      viajes.forEach((v) => {
        this.viajes.push({
          id: accion == 1 ? null : v.id,
          proyeccion_tif_planta_externa_id:
            accion == 1
              ? this.$route.params.data_edit.id
              : v.proyeccion_tif_planta_externa_id,
          producto: accion == 1 ? v.ticket.producto : v.producto,
          producto_id: accion == 1 ? v.ticket.producto_id : v.producto_id,
          tipo_vehiculo: v.tipo_vehiculo,
          tipo_vehiculo_id: v.tipo_vehiculo_id,
          empresa: accion == 1 ? v.transportadora : v.empresa,
          empresa_id: accion == 1 ? v.transportadora_id : v.empresa_id,
          barriles_servicio:
            accion == 1 ? v.promedio_volumen_calc : v.barriles_servicio,
          galones:
            accion == 1
              ? Math.round(parseFloat(v.promedio_volumen_calc) * 42)
              : v.galones,
          cant_vh: accion == 1 ? v.promedio_cant_vh : v.cant_vh,
          cant_turno: accion == 1 ? v.promedio_cant_turno : v.cant_turno,
          valor_tarifa: accion == 1 ? v.ticket.tarifa.tarifa : v.valor_tarifa,
          valor_format_tarifa: null,
          valor_total: accion == 1 ? v.valor_total : v.valor_total,
          total_valor_format: null,
        });
      });
      await this.dataDetallePlanta();
    },

    async totales() {
      this.viaje.valor_total =
        parseFloat(this.viaje.galones) * parseFloat(this.viaje.valor_tarifa);

      this.viaje.total_valor_format = this.formatCurrency(
        "es-CO",
        "COP",
        2,
        this.viaje.valor_total
      );
    },

    formatCurrency(locales, currency, fractionDigits, number) {
      var formato = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);

      return formato;
    },
  },
};
</script>
